import {List, Datagrid} from 'react-admin';
import {TextField, DateField, FunctionField} from 'react-admin';

import useInteldimObject from '../../hooks/useInteldimObject';

function LicenseList() {
    const {getObject, isSuccess, isError} = useInteldimObject();

    const renderObjectTitle = (id) => {
        if (isError) {
            return id;
        }

        const object = getObject(id);
        return object ? object['title'] : id;
    };

    const renderLicenseType = (is_trial) => (
        is_trial ? 'Пробний період' : 'Активована'
    );

    return (
        <List>
            {isSuccess || isError ? (
                <Datagrid rowClick={'edit'}>
                    <DateField source={'created_at'} label={'Створено'} locales={'uk-UA'}/>
                    <DateField source={'updated_at'} label={'Редаговано'} locales={'uk-UA'}/>
                    <FunctionField source={'object_id'} label={'Об\'єкт'}
                                   render={record => renderObjectTitle(record['object_id'])}/>
                    <TextField source={'engine_hours'} label={'Мотогодин'}/>
                    <FunctionField source={'is_trial'} label={'Стан ліцензії'}
                                   render={record => renderLicenseType(record['is_trial'])}/>
                </Datagrid>
            ) : (
                <></>
            )}
        </List>
    );
}

export default LicenseList;
