import { AutocompleteInput, BooleanInput, NumberInput, TextInput } from 'react-admin';
import { required, maxLength, maxValue, minValue } from 'react-admin';

import { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useGetInteldimObjects } from '../../../../hooks/hooks';

import { Grid, FormLabel } from '@mui/material';

const IS_TRIAL_DEFAULT = true;

const ENGINE_HOURS_DEFAULT = 0;
const ENGINE_HOURS_MIN = 0;
const ENGINE_HOURS_MAX = 65535;

const NOTE_MAX_LENGTH = 255;

const OBJECT_LABEL = 'Об\'єкт';
const IS_TRIAL_LABEL = 'Активовано пробний період';
const ENGINE_HOURS_LABEL = 'Кількість мотогодин';
const NOTE_LABEL = 'Примітка';

const REQUIRED_FIELD_MESSAGE = 'Обов\'язкове поле';

const ENGINE_HOURS_MIN_VALUE_MESSAGE = `Не може бути меншим за ${ENGINE_HOURS_MIN}`;
const ENGINE_HOURS_MAX_VALUE_MESSAGE = `Не може бути більшим за ${ENGINE_HOURS_MAX}`;

const NOTE_MAX_LENGTH_MESSAGE = `Не може бути більшим за ${NOTE_MAX_LENGTH}`;

function LicenseDescriptionFormTabContent({
                                              objectIdSource, isTrialSource,
                                              engineHorusSource, noteSource
                                          }) {
    const {objects, isSuccess} = useGetInteldimObjects();
    const [choices, setChoices] = useState([]);

    const isTrial = useWatch({
        name: isTrialSource,
        defaultValue: IS_TRIAL_DEFAULT
    });

    useEffect(() => {
        if (isSuccess) {
            const sortedStartsWithNumber = objects
                .filter(object => !isNaN(parseInt(object['title'])))
                .sort((a, b) => parseInt(a['title']) - parseInt(b[['title']]));

            const sortedStartsWithLetter = objects
                .filter(object => isNaN(parseInt(object['title'])))
                .sort((a, b) => a['title'].localeCompare(b['title']));

            const groupedByFirstSymbol = sortedStartsWithNumber.concat(sortedStartsWithLetter)
                .map(object => ({
                    firstSymbol: /[0-9]/.test(object['title'][0]) ? '0-9' : object['title'][0],
                    ...object
                }));

            setChoices(groupedByFirstSymbol);
        }
    }, [objects, isSuccess]);

    const groupByFirstSymbol = choices => choices.firstSymbol;

    const validateObjectId = [required(REQUIRED_FIELD_MESSAGE)];
    const validateIsTrial = [required(REQUIRED_FIELD_MESSAGE)];
    const validateEngineHours = [
        required(REQUIRED_FIELD_MESSAGE),
        minValue(ENGINE_HOURS_MIN, ENGINE_HOURS_MIN_VALUE_MESSAGE),
        maxValue(ENGINE_HOURS_MAX, ENGINE_HOURS_MAX_VALUE_MESSAGE)
    ];
    const validateNote = [maxLength(NOTE_MAX_LENGTH, NOTE_MAX_LENGTH_MESSAGE)];

    return (
        <Grid container maxWidth={'700px'} margin={'auto'} gap={1}>
            <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                Опис ліцензії
            </FormLabel>

            <Grid container flexDirection={'column'}>
                <AutocompleteInput source={objectIdSource}
                                   label={OBJECT_LABEL}
                                   choices={choices}
                                   optionText={'title'}
                                   groupBy={groupByFirstSymbol}
                                   validate={validateObjectId}
                                   fullWidth/>
                <BooleanInput source={isTrialSource}
                              label={IS_TRIAL_LABEL}
                              validate={validateIsTrial}
                              defaultValue={IS_TRIAL_DEFAULT}/>
                {isTrial ? (
                    <NumberInput source={engineHorusSource}
                                 label={ENGINE_HOURS_LABEL}
                                 validate={validateEngineHours}
                                 defaultValue={ENGINE_HOURS_DEFAULT}
                                 min={ENGINE_HOURS_MIN}
                                 max={ENGINE_HOURS_MAX}/>
                ) : null}
                <TextInput source={noteSource}
                           label={NOTE_LABEL}
                           validate={validateNote}
                           inputProps={{maxLength: NOTE_MAX_LENGTH}}
                           multiline fullWidth/>
            </Grid>
        </Grid>
    );
}

export default LicenseDescriptionFormTabContent;
