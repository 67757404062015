import {TabbedForm} from 'react-admin';

import LicenseDescriptionFormTabContent from './TabsContent/LicenseDescriptionFormTabContent';
import LicenseGenerateFromTabContent from './TabsContent/LicenseGenerateFromTabContent';

const OBJECT_ID_SOURCE = 'object_id';
const NOTE_SOURCE = 'note';

const IS_TRIAL_SOURCE = 'is_trial';
const ENGINE_HOURS_SOURCE = 'engine_hours';

const SERVER_PUBLIC_SOURCE = 'server_public';
const SERVER_PRIVATE_SOURCE = 'server_private';

const CLIENT_PUBLIC_SOURCE = 'client_public';
const CLIENT_PRIVATE_SOURCE = 'client_private';
const CLIENT_MAC_ADDRESS_SOURCE = 'client_mac_address';

const CLIENT_MESSAGE_SOURCE = 'client_message';
const SERVER_MESSAGE_SOURCE = 'server_message';


function LicenseTabbedForm({generateNewServerKeyPair}) {
    return (
        <TabbedForm mode={'onChange'}>
            <TabbedForm.Tab label={'Опис'}>
                <LicenseDescriptionFormTabContent objectIdSource={OBJECT_ID_SOURCE}
                                                  isTrialSource={IS_TRIAL_SOURCE}
                                                  engineHorusSource={ENGINE_HOURS_SOURCE}
                                                  noteSource={NOTE_SOURCE}/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={'Генерація'}>
                <LicenseGenerateFromTabContent serverPublicSource={SERVER_PUBLIC_SOURCE}
                                               serverPrivateSource={SERVER_PRIVATE_SOURCE}
                                               clientMessageSource={CLIENT_MESSAGE_SOURCE}
                                               clientPublicSource={CLIENT_PUBLIC_SOURCE}
                                               clientPrivateSource={CLIENT_PRIVATE_SOURCE}
                                               clientMacAddressSource={CLIENT_MAC_ADDRESS_SOURCE}
                                               isTrialSource={IS_TRIAL_SOURCE}
                                               engineHoursSource={ENGINE_HOURS_SOURCE}
                                               serverMessageSource={SERVER_MESSAGE_SOURCE}
                                               generateNewServerKeyPair={generateNewServerKeyPair}/>
            </TabbedForm.Tab>
        </TabbedForm>
    );
}

export default LicenseTabbedForm;
