import simpleRestProvider from 'ra-data-simple-rest';

class DataProvider extends simpleRestProvider {
    constructor(apiUrl, httpClient) {
        super(apiUrl, httpClient);

        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    generateNewKeyPair = () => {
        return this.httpClient(`${this.apiUrl}/cipher/generate_key_pair`)
            .then(response => response.json);
    }

    readClientMessage = ({clientMessage, serverPrivate}) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                client_message: {base32: clientMessage},
                server_private: {base32: serverPrivate}
            })
        };

        return this.httpClient(`${this.apiUrl}/cipher/read_client_message`, options)
            .then(response => response.json);
    };

    createServerMessage = ({isTrial, engineHours, clientPublic}) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                'server_payload': {
                    'is_trial': isTrial,
                    'engine_hours': engineHours
                },
                'client_public': {
                    'base32': clientPublic
                }
            })
        };

        return this.httpClient(`${this.apiUrl}/cipher/generate_server_message`, options)
            .then(response => response.json);
    }
}

export default DataProvider;
