import {TextInput, PasswordInput} from 'react-admin';

import {required, minLength, maxLength} from 'react-admin';

import {useDataProvider, useNotify} from 'react-admin';
import {useFormContext} from 'react-hook-form';
import {useMutation} from 'react-query';

import {Grid, FormLabel} from '@mui/material';

const CLIENT_MESSAGE_LABEL = 'Повідомлення клієнта';
const CLIENT_MESSAGE_HELPER_TEXT = 'Введіть повідомлення клієнта';

const CLIENT_PUBLIC_KEY_LABEL = 'Публічний ключ';
const CLIENT_PRIVATE_KEY_LABEL = 'Приватний ключ';
const CLIENT_MAC_ADDRESS_LABEL = 'MAC-адреса';

const CLIENT_MESSAGE_LENGTH = 45;

const CLIENT_MESSAGE_LENGTH_MESSAGE = `Повинно містити ${CLIENT_MESSAGE_LENGTH} символів`;
const REQUIRED_FIELD_MESSAGE = 'Обов\'язкове поле';

const READ_CLIENT_MESSAGE_SUCCESS_MESSAGE = 'Повідомлення клієнта успішно прочитано';

function ReadClientMessage({
                               clientMessageSource, clientPublicSource,
                               clientPrivateSource, clientMacAddressSource,
                               serverPrivate,
                               onSuccess = data => {},
                               onError = error => {}
                           }) {
    const notify = useNotify();

    const {
        getValues, setValue, setError,
        getFieldState, resetField
    } = useFormContext();
    const {readClientMessage} = useDataProvider();
    const {mutate} = useMutation(readClientMessage, {
        onSuccess: data => {
            setValue(clientPublicSource, data['public']['base32'])
            setValue(clientPrivateSource, data['private']['base32'])
            setValue(clientMacAddressSource, data['mac_address'])

            notify(READ_CLIENT_MESSAGE_SUCCESS_MESSAGE, {type: 'success'});

            onSuccess(data);
        },
        onError: error => {
            setError(clientMessageSource, {message: error.message});
            notify(error.message, {type: 'error'});

            resetField(clientPublicSource, {defaultValue: null});
            resetField(clientPrivateSource, {defaultValue: null});
            resetField(clientMacAddressSource, {defaultValue: null});

            onError(error);
        }
    });

    const onBlurClientMessageField = () => {
        const clientMessage = getValues()[clientMessageSource];
        const clientMessageFieldState = getFieldState(clientMessageSource);

        if (!clientMessageFieldState.invalid && clientMessage !== null) {
            mutate({clientMessage, serverPrivate});
        }
    };

    const validateClientMessage = [
        required(REQUIRED_FIELD_MESSAGE),
        minLength(CLIENT_MESSAGE_LENGTH, CLIENT_MESSAGE_LENGTH_MESSAGE),
        maxLength(CLIENT_MESSAGE_LENGTH, CLIENT_MESSAGE_LENGTH_MESSAGE)
    ];
    const validateClientPublic = required(REQUIRED_FIELD_MESSAGE);
    const validateClientPrivate = required(REQUIRED_FIELD_MESSAGE);
    const validateClientMacAddress = required(REQUIRED_FIELD_MESSAGE);

    return (
        <Grid container>
            <Grid container flexDirection={'column'}>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Повідомлення клієнта
                </FormLabel>
                <TextInput source={clientMessageSource}
                           label={CLIENT_MESSAGE_LABEL}
                           helperText={CLIENT_MESSAGE_HELPER_TEXT}
                           validate={validateClientMessage}
                           inputProps={{maxLength: CLIENT_MESSAGE_LENGTH}}
                           onBlur={onBlurClientMessageField}/>
            </Grid>

            <Grid container flexDirection={'column'}>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Дані клієнта
                </FormLabel>
                <Grid container gap={1} justifyContent={'space-between'}>
                    <Grid item width={'330px'}>
                        <PasswordInput source={clientPublicSource}
                                       label={CLIENT_PUBLIC_KEY_LABEL}
                                       validate={validateClientPublic}
                                       fullWidth disabled/>
                    </Grid>
                    <Grid item width={'330px'}>
                        <PasswordInput source={clientPrivateSource}
                                       label={CLIENT_PRIVATE_KEY_LABEL}
                                       validate={validateClientPrivate}
                                       fullWidth disabled/>
                    </Grid>
                </Grid>
                <TextInput source={clientMacAddressSource}
                           label={CLIENT_MAC_ADDRESS_LABEL}
                           validate={validateClientMacAddress}
                           disabled/>
            </Grid>
        </Grid>
    );
}

export default ReadClientMessage;
