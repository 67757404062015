import {TextInput} from 'react-admin';

import {required} from 'react-admin';

import {useDataProvider, useNotify} from 'react-admin';
import {useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {useMutation} from 'react-query';

import {Grid, FormLabel} from '@mui/material';

const SERVER_MESSAGE_LABEL = 'Повідомлення сервера';
const SERVER_MESSAGE_HELPER_TEXT = 'Передайте клієнту повідомлення сервера';

const REQUIRED_FIELD_MESSAGE = 'Обов\'язкове поле';

const CREATE_SERVER_MESSAGE_SUCCESS_MESSAGE = 'Успішно згенеровано ліцензію!';

function CreateServerMessage({
                                 clientPublic, engineHours, isTrial,
                                 serverMessageSource,
                                 onSuccess = data => {},
                                 onError = error => {}
                             }) {
    const notify = useNotify();

    const {getValues, setValue, resetField} = useFormContext();
    const {createServerMessage} = useDataProvider();
    const {mutate} = useMutation(createServerMessage, {
        onSuccess: (data) => {
            setValue(serverMessageSource, data['base32']);
            notify(CREATE_SERVER_MESSAGE_SUCCESS_MESSAGE, {type: 'success'});

            onSuccess(data);
        },
        onError: (error) => {
            resetField(serverMessageSource, {defaultValue: null});
            notify(error.message, {type: 'error'});

            onError(error);
        }
    })

    const [copy, setCopy] = useState({clientPublic, engineHours, isTrial});

    useEffect(() => {
        if ((clientPublic === null) || (engineHours === null) || (isTrial === null)) {
            setCopy({engineHours: null, isTrial: null, clientPublic: null});

            return;
        }

        if ((clientPublic === copy.clientPublic)
            && (engineHours === copy.engineHours)
            && (isTrial === copy.isTrial)) {
            return;
        }

        mutate({engineHours, isTrial, clientPublic});
        setCopy({engineHours, isTrial, clientPublic});
    }, [
        copy, setCopy,
        clientPublic, engineHours,
        isTrial, mutate,
        getValues, serverMessageSource
    ]);

    const validate = required(REQUIRED_FIELD_MESSAGE);

    return (
        <Grid container flexDirection={'column'}>
            <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                Повідомлення сервера (Ліцензія)
            </FormLabel>
            <TextInput source={serverMessageSource}
                       label={SERVER_MESSAGE_LABEL}
                       helperText={SERVER_MESSAGE_HELPER_TEXT}
                       validate={validate}
                       fullWidth disabled/>
        </Grid>
    );
}

export default CreateServerMessage;
