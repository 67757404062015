class AuthProvider {
    constructor(tokenUrl) {
        this.tokenUrl = tokenUrl;
    }

    login = ({username, password}) => {
        const creedsForm = new FormData();
        creedsForm.append('username', username);
        creedsForm.append('password', password);

        const setCookies = data => {
            localStorage.setItem('accessToken', JSON.stringify(data['access_token']))
        };

        return fetch(this.tokenUrl, {method: 'POST', body: creedsForm})
            .then(response => {
                if (response.status === 401) {
                    throw new Error('Невірний логін або пароль');
                }

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(data => setCookies(data));
    };

    checkError = error => {
        if (error.status === 401) {
            localStorage.removeItem('accessToken');

            return Promise.reject();
        }

        return Promise.resolve();
    }

    checkAuth = params => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken === null) {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    logout = () => {
        localStorage.removeItem('accessToken');

        return Promise.resolve()
    }

    getIdentity = () => Promise.resolve()

    getPermissions = () => Promise.resolve()
}
export default AuthProvider;
