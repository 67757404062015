import {Edit} from 'react-admin';

import LicenseTabbedForm from './Form/LicenseTabbedForm';

function LicenseEdit() {
    return (
        <Edit>
            <LicenseTabbedForm/>
        </Edit>
    );
}

export default LicenseEdit;
