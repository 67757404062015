import GenerateServerKeyPair from '../Utils/GenerateServerKeyPair';
import ReadClientMessage from '../Utils/ReadClientMessage';
import CreateServerMessage from '../Utils/CreateServerMessage';

import {useWatch, useFormContext} from 'react-hook-form';

import {Grid, FormLabel} from '@mui/material';

function LicenseGenerateFromTabContent({
                                           serverPublicSource, serverPrivateSource, clientMessageSource,
                                           clientPublicSource, clientPrivateSource, clientMacAddressSource,
                                           isTrialSource, engineHoursSource, serverMessageSource,
                                           generateNewServerKeyPair = false
                                       }) {
    const serverPrivate = useWatch({name: serverPrivateSource});
    const clientPublic = useWatch({name: clientPublicSource});
    const isTrial = useWatch({name: isTrialSource});
    const engineHours = useWatch({name: engineHoursSource});

    const {resetField} = useFormContext();

    const onSuccessGenerateServerKeyPair = () => {
        resetField(clientMessageSource, {defaultValue: null});
        resetField(clientPublicSource, {defaultValue: null});
        resetField(clientPrivateSource, {defaultValue: null});
        resetField(clientMacAddressSource, {defaultValue: null});
        resetField(serverMessageSource, {defaultValue: null});
    };

    const onErrorReadClientMessage = () => {
        resetField(clientPublicSource, {defaultValue: null});
        resetField(clientPrivateSource, {defaultValue: null});
        resetField(clientMacAddressSource, {defaultValue: null});
        resetField(serverMessageSource, {defaultValue: null});
    };

    return (
        <Grid container maxWidth={'700px'} margin={'auto'} gap={1}>
            <Grid container>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Крок 1
                </FormLabel>
                <GenerateServerKeyPair serverPublicSource={serverPublicSource}
                                       serverPrivateSource={serverPrivateSource}
                                       generateNewServerKeyPair={generateNewServerKeyPair}
                                       onSuccess={onSuccessGenerateServerKeyPair}/>
            </Grid>
            <Grid container>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Крок 2
                </FormLabel>
                <ReadClientMessage clientMessageSource={clientMessageSource}
                                   clientPublicSource={clientPublicSource}
                                   clientPrivateSource={clientPrivateSource}
                                   clientMacAddressSource={clientMacAddressSource}
                                   serverPrivate={serverPrivate}
                                   onError={onErrorReadClientMessage}/>
            </Grid>
            <Grid container>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Крок 3
                </FormLabel>
                <CreateServerMessage serverMessageSource={serverMessageSource}
                                     clientPublic={clientPublic}
                                     engineHours={engineHours}
                                     isTrial={isTrial}/>
            </Grid>
        </Grid>
    );
}

export default LicenseGenerateFromTabContent;
