import {Create} from 'react-admin';

import LicenseTabbedForm from './Form/LicenseTabbedForm';

function LicenseCreate() {
    return (
        <Create>
            <LicenseTabbedForm generateNewServerKeyPair/>
        </Create>
    );
}

export default LicenseCreate;
