import {TextInput, PasswordInput, Button} from 'react-admin';

import {required} from 'react-admin';

import {useDataProvider, useNotify} from 'react-admin';
import {useFormContext} from 'react-hook-form';
import {useMutation} from 'react-query';
import {useEffect} from 'react';

import {FormLabel, Grid} from '@mui/material';

const PUBLIC_KEY_LABEL = 'Публічний ключ';
const PUBLIC_KEY_HELPER_TEXT = 'Передайте публічний ключ клієнту';

const PRIVATE_KEY_LABEL = 'Приватний ключ';

const GENERATE_NEW_KEY_PAIR_BUTTON_LABEL = 'Згенерувати нову пару';

const REQUIRED_FIELD_MESSAGE = 'Не може бути порожнім';

const GENERATE_NEW_KEY_PAIR_SUCCESS_MESSAGE = 'Згенеровано нову пару';
const GENERATE_NEW_KEY_PAIR_ERROR_MESSAGE = 'Помилка генерації пари ключів';

function GenerateServerKeyPair({
                                   serverPublicSource, serverPrivateSource,
                                   generateNewServerKeyPair = false,
                                   onSuccess = data => {},
                                   onError = error => {}
                               }) {
    const notify = useNotify()

    const {setValue} = useFormContext();
    const {generateNewKeyPair} = useDataProvider();
    const {mutate} = useMutation(generateNewKeyPair, {
        onSuccess: data => {
            setValue(serverPublicSource, data['public']['base32']);
            setValue(serverPrivateSource, data['private']['base32']);

            notify(GENERATE_NEW_KEY_PAIR_SUCCESS_MESSAGE, {type: 'info'});

            onSuccess(data);
        },
        onError: error => {
            notify(GENERATE_NEW_KEY_PAIR_ERROR_MESSAGE, {type: 'error'});

            onError(error);
        }
    });

    useEffect(() => {
        if (generateNewServerKeyPair) {
            mutate();
        }
    }, [generateNewServerKeyPair, mutate]);

    const validate = required(REQUIRED_FIELD_MESSAGE);

    return (
        <Grid container>
            <Grid container justifyContent={'space-between'} gap={1}>
                <FormLabel sx={{fontSize: '20px', fontWeight: 'bold'}}>
                    Серверна пара
                </FormLabel>
                <Button label={GENERATE_NEW_KEY_PAIR_BUTTON_LABEL}
                        variant={'outlined'}
                        size={'small'}
                        onClick={mutate}/>
            </Grid>
            <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item width={'330px'}>
                    <TextInput source={serverPublicSource}
                               label={PUBLIC_KEY_LABEL}
                               helperText={PUBLIC_KEY_HELPER_TEXT}
                               validate={validate}
                               fullWidth disabled/>
                </Grid>
                <Grid item width={'330px'}>
                    <PasswordInput source={serverPrivateSource}
                                   label={PRIVATE_KEY_LABEL}
                                   validate={validate}
                                   fullWidth disabled/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default GenerateServerKeyPair;
