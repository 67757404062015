import {useEffect} from 'react';

import {useMutation} from 'react-query';

function useGetInteldimObjects() {
    const {mutate, data, isLoading, isSuccess, isError} = useMutation(() => (
        fetch('https://report-work.inteldim.support/api/get-objects/')
            .then(response => response
                .json()
            )
            .then(data => data
                .filter(node => node['is_active'] && !node['is_group'])
                .map(object => ({
                    ...object,
                    id: parseInt(object['id'])
                }))
            )
    ));

    useEffect(() => {
        mutate();
    }, [mutate]);

    return {objects: data, isLoading, isSuccess, isError};
}

export default useGetInteldimObjects;
