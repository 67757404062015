import useGetInteldimObjects from './useGetInteldimObjects';

function useInteldimObject() {
    const {objects, isSuccess, isError} = useGetInteldimObjects();

    const getObject = (id) => objects.find(
        object => object['id'] === id
    );

    return {getObject, isSuccess, isError};
}

export default useInteldimObject;
