import {Admin, Resource} from 'react-admin';

import {fetchUtils} from 'react-admin';

import LicenseList from './License/LicenseList';
import LicenseCreate from './License/LicenseCreate';
import LicenseEdit from './License/LicenseEdit';

import DataProvider from '../providers/dataProvider';
import AuthProvider from '../providers/authProvider';

function httpClient(url, options = {}) {
    const accessToken = localStorage.getItem('accessToken');

    options.headers = new Headers({
        'Authorization': `bearer ${JSON.parse(accessToken)}`
    });

    return fetchUtils.fetchJson(url, options);
}

const BASE_URL = process.env.REACT_APP_API_URL;

const authProvider = new AuthProvider(`${BASE_URL}/auth/token`);
const dataProvider = new DataProvider(BASE_URL, httpClient);

const App = () => (
    <Admin authProvider={authProvider}
           dataProvider={dataProvider}>
        <Resource name='license'
                  list={LicenseList}
                  create={LicenseCreate}
                  edit={LicenseEdit}/>
    </Admin>
);

export default App;
